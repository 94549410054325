import request from '@/utils/request'

// 列出所有忌辰提醒
export function findDeathDayReminds(params) {
  return request({
    url: `/death_day_reminds`,
    method: 'get',
    params: params
  })
}
// 更新忌辰提醒状态
export function updateDeathDayRemindEffective(id, data) {
  return request({
    url: `/death_day_reminds/${id}/effective`,
    method: 'patch',
    data
  })
}
// 更新忌辰提醒信息
export function updateDeathDayRemind(id, data) {
  return request({
    url: `/death_day_reminds/${id}`,
    method: 'put',
    data
  })
}
// 创建忌辰提醒
export function createDeathDayRemind(data) {
  return request({
    url: `/death_day_reminds`,
    method: 'post',
    data
  })
}
// 删除忌辰提醒
export function deleteDeathDayRemind(id) {
  return request({
    url: `/death_day_reminds/${id}`,
    method: 'delete'
  })
}
